import _ from 'lodash';

export default {
	name: 'form-code',

	components: {
		'ace-editor': require('vue2-ace-editor'),
	},

	props: [
		'value',
		'label',
		'rules',
		'required',
		'language',
	],

	data() {
		return {
			errors: [],
		};
	},

	computed: {
		jsonData: {
			get() {
				let res = this.value || '';

				if (typeof this.value !== 'string') {
					try {
						res = JSON.stringify(this.value, null, 4);
					} catch (error) {
						console.log('FormCode (line : 27) | computed:jsonData | error : ', error);
						res = `${res}`;
					}
				}

				return res;
			},
			set(val) {
				if (this.rules && this.rules.length) this.validate(this.rules, val);

				this.$emit('input', val);
			},
		},
		lang() {
			return this.language || 'javascript';
		},
	},

	methods: {
		async validate(rules, value) {
			this.errors = [];

			await Promise.all(
				_.map(rules, async(validator) => {
					const result = await validator(value);
					if (result !== true) this.errors.push(result);
				}),
			);

			const isValid = (this.errors.length === 0);
			this.$emit('valid', isValid);
		},
		editorInit() {
			require('brace/mode/json');
			require('brace/mode/javascript');
			require('brace/theme/monokai');
			require('brace/snippets/json');
			require('brace/snippets/javascript');
		},
	},

	created() {
		if (this.rules && this.rules.length) this.validate(this.rules, this.jsonData);
	},
};
